<template>
  <!-- 分页 组件 -->
  <div class="bodys">
    <!-- <div class="margin-right-sm">{{ total_records }}条记录，共{{ max }}页</div> -->
    <!-- <div @click="handleHome" class="cursor">首页</div> -->
    <el-pagination
      background
      layout="total, sizes, prev, pager, next, jumper"
      :page-count="max"
      :page-sizes="[10, 50, 100,500]"
      :page-size="size"
      :current-page="now"
      :total="total_records"
      @current-change="change"
      @size-change="handleSizeChange"
    >
    </el-pagination>
    <!-- <div @click="handleLast" class="cursor margin-right-xl">末页</div> -->
  </div>
</template>

<script>
export default {
  props: {
    max: {
      default: () => 1,
    },
    now: {
      default: () => 1,
    },
    size: {
      default: () => 10,
    },
    total_records: {
      default: () => 0,
    },
  },

  methods: {
    change(val) {
      this.$emit("update:now", val);
      this.$emit("change");
    },
    handleSizeChange(val) {
      this.$emit("update:size", val);
    },
    handleHome() {
      this.$emit("page_change");
    },
    handleLast() {
      this.$emit("page_change", this.max);
    },
  },
};
</script>

<style  lang="scss" >
.bodys {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>