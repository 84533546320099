import moment from 'moment'
let filter = {
	ftime(start,end){
		let st,en,diff
		st = moment(start).valueOf();
		en = moment(end).valueOf();
		diff = moment(en).diff(moment(st), 'seconds') 

		var hours = parseInt((diff % ( 60 * 60 * 24)) / ( 60 * 60));
		var minutes = parseInt((diff % (60 * 60)) / 60);
		var seconds = diff % 60;

		if(hours<10){
			hours = '0' + hours
		}
		if(minutes<10){
			minutes = '0' + minutes
		}
		if(seconds<10){
			seconds = '0' + seconds
		}
		return `${hours}:${minutes}:${seconds}`
	},
    setSeatImg: (seat, state) => {
		//-1为非座位，0为未购座位，1为已选座位(绿色),2为已购座位(红色)
		let img = ''
		let data = [
			{
				'0': require('../../assets/img/normal_seat.jpg'),
				'1': require('../../assets/img/normal_seat_choose.jpg'),
				'2': require('../../assets/img/normal_seat_active.jpg'),
			},
			{
				'0': require('../../assets/img/couple_seat_left.jpg'),
				'1': require('../../assets/img/couple_left_choose.jpg'),
				'2': require('../../assets/img/couple_left_active.jpg'),
			},
			{
				'0': require('../../assets/img/couple_seat_right.jpg'),
				'1': require('../../assets/img/couple_right_choose.jpg'),
				'2': require('../../assets/img/couple_right_active.jpg'),
			},
		]
		img = data[seat][state]
		return img
	},

	/**
	 * 时间格式化
	 * @param {*} date 时间
	 */
	formateDate: (date, format) => {
		return moment(date).utcOffset(8).format(format)
	},
	

	 // 判断日期为周几
	getWeekByDay: (date) => {
		date = new Date(filter.formateDate(date, 'YYYY/MM/DD'))
		var d1 = date
		var d2 = new Date(filter.formateDate(new Date(), 'YYYY/MM/DD')) //new Date((new Date()).toLocaleDateString())
		var iday = parseInt(d1 - d2) / 1000 / 60 / 60 / 24
		if (iday === 0) {
			return '今天'
		}
		if (iday === 1) {
			return '明天'
		}
		if (iday === 2) {
			return '后天'
		}
		var week
		if (date.getDay() === 0) week = '周日'
		if (date.getDay() === 1) week = '周一'
		if (date.getDay() === 2) week = '周二'
		if (date.getDay() === 3) week = '周三'
		if (date.getDay() === 4) week = '周四'
		if (date.getDay() === 5) week = '周五'
		if (date.getDay() === 6) week = '周六'
		return week
	},

	      // 订单
		  h5OrMini(val){
            // console.log(val)
            if(val == 0){
                return '网页'
            }else{
                return '小程序'
            }
        },
        // 订单
        jingjiaCount(val){
            if(val == 0){
                return '(无人报价)'
            }else if(val == null){
                return '(无人报价)'
            }else{
                return `(${val}人报价)`
            }
        },

        // 距离放映
        distanceShow(val){
            // 获取当前时间戳
            let currentTime = moment().format('x')
            // show时间戳
            let showTime = moment(val).format('x')
            // 时间差
            let day = ''
            let hour = ''
            let min = ''
            // let leftTime  = Math.abs(showTime - currentTime) 
            let leftTime  = showTime - currentTime
            if(leftTime > 0){
                day = Math.floor(leftTime / (1000 * 24 * 60 * 60))
				hour = Math.floor((leftTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				min = Math.floor((leftTime % (1000 * 60 * 60)) / (1000 * 60))
                // console.log(`${day}天${hour}小时${min}分钟`)
                return `${day}天${hour}小时${min}分钟`
            }else{
                return '已放映'
            }
        },

        // 状态 - 待出票
        waitTicket(val){
            // console.log(val)
            // 获取当前时间戳
            let currentTime = moment().format('x')
            // show时间戳
            let creatTime = moment(val).format('x')
            // 时间差
            let day = ''
            let hour = ''
            let min = ''
            // let leftTime  = Math.abs(showTime - currentTime) 
            let leftTime  = currentTime - creatTime
			// console.log(leftTime)
            day = Math.floor(leftTime / (1000 * 24 * 60 * 60))
            hour = Math.floor((leftTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            min = Math.floor((leftTime % (1000 * 60 * 60)) / (1000 * 60))
			// console.log(day,hour,min)
                // console.log(`${day}天${hour}小时${min}分钟`)
            return `${day}天${hour}小时${min}分钟前`
        },

		// 场次座位 - 更换场次 时间
		formatTime1(val){
			let arr = val.split('-')
			return `${arr[1]}月${arr[2]}日`
		},
		/**
	 * 延长时间
	 * @param {*} date 时间
	 * @param {*} time 延长的时间
	 * @param {*} type 延长单位，如d:天，m:分钟
	 * @param {*} format 时间格式
	 */
	getExtendTime: (date, time, type, format) => {
		return moment(date).add(time, type).format(format);
	},

	getToTime: (date, format) => {
		return moment(date).format(format);
	}

}

export default filter