import request from "./request";

const api = {
	login: (data = {}) => {
		return request({
			url: "/sys/login",
			method: "POST",
			path: '',
			data,
		});
	},
	// 获取首页数据
	getOrderSurvey: (data = {}) => {
		return request({
			url: "/sys/getOrderSurvey",
			method: "POST",
			data,
			path: '/first'
		});
	},
	//获取用户数统计图
	getUserCount: (data = {}) => {
		return request({
			url: "/sys/getUserCount",
			method: "POST",
			data,
			path: '/first'
		});
	},
	//获取折线统计图
	getStatistics: (data = {}) => {
		return request({
			url: "/sys/getStatistics",
			method: "POST",
			data,
			path: '/first'
		});
	},
	//获取访客比例统计
	getVisitor: (data = {}) => {
		return request({
			url: "/sys/getVisitor",
			method: "POST",
			data,
			path: '/first'
		});
	},
	// 订单管理-竞价列表
	getOffer: (data = {}) => {
		return request({
			url: "/sys/getOffer",
			method: "POST",
			data,
			path: '/order'
		});
	},
	// 订单管理-撤销报价
	cancelOffer: (data = {}) => {
		return request({
			url: "/sys/cancelOffer",
			method: "POST",
			data,
			path: '/order'
		});
	},
	// 订单管理-退回订单
	setOrder: (data = {}) => {
		return request({
			url: "/sys/setOrder",
			method: "POST",
			data,
			path: '/order'
		});
	},
	// 订单管理-获取座位表
	getSeatInfo: (data = {}) => {
		return request({
			url: "/sys/getSeatInfo",
			method: "POST",
			data,
			path: '/order'
		});
	},
	// 订单管理-获取场次
	getShows: (data = {}) => {
		return request({
			url: "/sys/getShows",
			method: "POST",
			data,
			path: '/order'
		});
	},
	// 订单管理-出票详情（列表点击）
	getTicketDetailByNo: (data = {}) => {
		return request({
			url: "/sys/getTicketDetailByNo",
			method: "POST",
			data,
			path: '/order'
		});
	},

	// 订单管理-获取票据详情
	getTicketImg: (data = {}) => {
		return request({
			url: "/sys/getTicketImg",
			method: "POST",
			data,
			path: '/order'
		});
	},

	// 订单管理-手动写入取票码
	setTicketCode: (data = {}) => {
		return request({
			url: "/sys/setTicketCode",
			method: "POST",
			data,
			path: '/order'
		});
	},

	// 订单管理-点击出票
	subTicket: (data = {}) => {
		return request({
			url: "/sys/subTicket",
			method: "POST",
			data,
			path: '/order'
		});
	},

	// 订单管理-删除取票截图
	delTicketImg: (data = {}) => {
		return request({
			url: "/sys/delTicketImg",
			method: "POST",
			data,
			path: '/order'
		});
	},

	// 订单管理-更新座位表
	updateSeatInfo: (data = {}) => {
		return request({
			url: "/sys/updateSeatInfo",
			method: "POST",
			data,
			path: '/order'
		});
	},

	// 订单管理-上传图片
	sysUploadImg: (data = {}) => {
		return request({
			url: "/sys/sysUploadImg",
			method: "POST",
			data,
			path: '/order'
		});
	},

	// 订单管理-释放座位
	unLock: (data = {}) => {
		return request({
			url: "/sys/unLock",
			method: "POST",
			data,
			path: '/order'
		});
	},

	// 订单管理-下载订单excel
	toFinanceExcel: (data = {}) => {
		return request({
			url: "/sys/toFinanceExcel",
			method: "POST",
			data,
			path: '/order'
		});
	},
	// 订单管理-转单接口
	transferOrder: (data = {}) => {
		return request({
			url: "/sys/transferOrder",
			method: "POST",
			data,
			path: '/order'
		});
	},

	//用户管理-冻结
	frozenBuyer: (data = {}) => {
		return request({
			url: "/sys/frozenBuyer",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-删除
	delUser: (data = {}) => {
		return request({
			url: "/sys/delUser",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-买家-获取买家
	getBuyer: (data = {}) => {
		return request({
			url: "/sys/getBuyer",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-买家-编辑权限
	setBuyer: (data = {}) => {
		return request({
			url: "/sys/setBuyer",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-买家-设置羊毛党
	setWool: (data = {}) => {
		return request({
			url: "/sys/setWool",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-商家-获取商家
	getSeller: (data = {}) => {
		return request({
			url: "/sys/getSeller",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-商家-编辑商家
	setSeller: (data = {}) => {
		return request({
			url: "/sys/setSeller",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-推广-获取推广
	getPusher: (data = {}) => {
		return request({
			url: "/sys/getPusher",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-推广-降权
	downAuth: (data = {}) => {
		return request({
			url: "/sys/downAuth",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-推广-初始化密码
	initPass: (data = {}) => {
		return request({
			url: "/sys/initPass",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-推广-编辑
	setPusher: (data = {}) => {
		return request({
			url: "/sys/setPusher",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-推广-分享
	getShareUrl: (data = {}) => {
		return request({
			url: "/sys/getShareUrl",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-客户-获取客户
	getCustomer: (data = {}) => {
		return request({
			url: "/sys/getCustomer",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-客户-搜索客户
	getSearchData: (data = {}) => {
		return request({
			url: "/sys/getSearchData",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-客户-添加新秘钥
	setSecret: (data = {}) => {
		return request({
			url: "/sys/setSecret",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-客户-删除客户
	deleteCustomer: (data = {}) => {
		return request({
			url: "/sys/deleteCustomer",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-客户-更新密钥
	updateCustomer: (data = {}) => {
		return request({
			url: "/sys/updateCustomer",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//用户管理-获取系统分销
	getSysShare: (data = {}) => {
		return request({
			url: "/sys/getSysShare",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	getCardBag: (data = {}) => {
		return request({
			url: "/sys/getUcard",
			method: "POST",
			data,
			path: '/user,/userBusiness,/userExtension,/userCustomer'
		});
	},
	//常见问题-列表
	getQuestion: (data = {}) => {
		return request({
			url: "/sys/getQuestion",
			method: "POST",
			data,
			path: 'problem'
		});
	},
	//常见问题-发布,修改
	setQuestion: (data = {}) => {
		return request({
			url: "/sys/setQuestion",
			method: "POST",
			data,
			path: 'problem'
		});
	},
	//常见问题-删除
	deleteQuestion: (data = {}) => {
		return request({
			url: "/sys/deleteQuestion",
			method: "POST",
			data,
			path: 'problem'
		});
	},
	//广告管理-获取广告列表
	getAd: (data = {}) => {
		return request({
			url: "/sys/getAd",
			method: "POST",
			data,
			path: 'advert'
		});
	},
	//广告管理-获取电影列表
	getMovie: (data = {}) => {
		return request({
			url: "/sys/getData",
			method: "POST",
			data,
			path: 'advert'
		});
	},
	//广告管理-添加，编辑、删除、下架
	setAd: (data = {}) => {
		return request({
			url: "/sys/setAd",
			method: "POST",
			data,
			path: 'advert'
		});
	},
	uploadAd: (data = {}) => {
		return request({
			url: "/upload/UploadAd",
			method: "POST",
			data,
			path: 'advert'
		});
	},
	//零钱提现-获取提现列表
	getCash: (data = {}) => {
		return request({
			url: "/sys/getCash",
			method: "POST",
			data,
			path: 'toCash'
		});
	},
	//零钱提现-提现
	toCash: (data = {}) => {
		return request({
			url: "/sys/toCash",
			method: "POST",
			data,
			path: 'toCash'
		});
	},

	// 获取订单列表
	getOrder: (data = {}) => {
		return request({
			url: "/sys/getOrder",
			method: "POST",
			data,
			path: '/order'
		});
	},
	getOrderDetail: (data = {}) => {
		return request({
			url: "/sys/getOrderDetail",
			method: "POST",
			data,
			path: '/order'
		});
	},
	// 影院规则-影院列表
	getCinemaList: (data = {}) => {
		return request({
			url: "/sys/getCinemaList",
			method: "POST",
			data,
			path: '/cinema'
		});
	},
	// 影院规则-设置影院列表
	setCinemaList: (data = {}) => {
		return request({
			url: "/sys/setCinemaList",
			method: "POST",
			data,
			path: '/cinema'
		});
	},
	// 影院规则- 批量操作影院列表
	setSomeCinema: (data = {}) => {
		return request({
			url: "/sys/setSomeCinema",
			method: "POST",
			data,
			path: '/cinema'
		});
	},

	//卡券列表
	sysGetCard: (data = {}) => {
		return request({
			url: "/sys/getCard",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	//卡券列表添加编辑删除
	sysSetCard: (data = {}) => {
		return request({
			url: "/sys/setCard",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	//获取电影下la
	sysGetData: (data = {}) => {
		return request({
			url: "/sys/getData",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	//获取影城下拉
	sysGetCinema: (data = {}) => {
		return request({
			url: "/sys/getCinema",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	//用户下拉
	sysGetUser: (data = {}) => {
		return request({
			url: "/sys/getUser",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	//优惠券详情
	getCardDetail: (data = {}) => {
		return request({
			url: "/sys/getCardDetail",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},

	getCardVip: (data = {}) => {
		return request({
			url: "/sys/getCardVip",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	setCardVip: (data = {}) => {
		return request({
			url: "/sys/setCardVip",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	getCardVipLog: (data = {}) => {
		return request({
			url: "/sys/getCardVipLog",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	sendSms: (data = {}) => {
		return request({
			url: "/sys/sendSms",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	phoneVerification: (data = {}) => {
		return request({
			url: "/sys/phoneVerification",
			method: "POST",
			data,
			path: '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
		});
	},
	// 权限
	setAuth: (data = {}) => {
		return request({
			url: "/sys/setAuth",
			method: "POST",
			data,
			path: '/menuUser,/menuAuth'
		});
	},
	// 权限
	getAuth: (data = {}) => {
		return request({
			url: "/sys/getAuth",
			method: "POST",
			data,
			path: '/menuUser,/menuAuth'
		});
	},
	// 权限
	setAdmin: (data = {}) => {
		return request({
			url: "/sys/setAdmin",
			method: "POST",
			data,
			path: '/menuUser,/menuAuth'
		});
	},
	getAdmin: (data = {}) => {
		return request({
			url: "/sys/getAdmin",
			method: "POST",
			data,
			path: '/menuUser,/menuAuth'
		});
	},
	getAuthAdmin: (data = {}) => {
		return request({
			url: "/sys/getAuthAdmin",
			method: "POST",
			data,
			path: '/menuUser,/menuAuth'
		});
	},
	setAuthAdmin: (data = []) => {
		return request({
			url: "/sys/setAuthAdmin",
			method: "POST",
			data,
			path: '/menuUser,/menuAuth'
		});
	},

	// 影院承包
	distributionAdd: (data = []) => {
		return request({
			url: "/sys/distributionAdd",
			method: "POST",
			data,
			path: '/Contracting'
		});
	},
	//  pub
	distributionList: (data = []) => {
		return request({
			url: "/sys/distributionList",
			method: "POST",
			data,
			path: '/Contracting'
		});
	},
	// 获取省份 pub
	getProvince(data = {}) {
		return request({
			url: '/sys/getProvince',
			method: 'POST',
			data,
			path: '/Contracting'
		})
	},
	// 获取城市 pub
	getCity(data = {}) {
		return request({
			url: '/sys/getCity',
			method: 'POST',
			data,
			path: '/Contracting'
		})
	},





	distributionSort: (data = {}) => {
		return request({
			url: "/sys/distributionSort",
			method: "POST",
			data,
			path: ''
		});
	},
	// -------公共接口
	// 城市下拉
	getCityLinkage: (data = {}) => {
		return request({
			url: "/sys/getCityLinkage",
			method: "GET",
			data,
			path: ''
		});
	},

};

export default api;
