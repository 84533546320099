import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import {closeSocket,socketCon} from '../utils/wrbScoket/websocket'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
    // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/',
    redirect:'/home'
  },
  {
    path:'/login',
    name:'Login',
    component:() => import('../views/Login.vue')
  },
  {
    path:'/home',
    name:'Home',
    redirect:'/first',
    component:Home,
    children:[
      {
        path:'/first',
        name:'First',
        component:() => import('../views/First.vue'),
        meta:{
          title:'首页预览'
        }
      },
      {
        path:'/order',
        name:'Order',
        component:() => import('../views/OrderManage.vue'),
        meta:{
          title:'订单管理'
        }
      },
      {
        path:'/user',
        name:'User',
        component:() => import('../views/UserManage.vue'),
        meta:{
          title:'用户管理'
        }
      },
	  {
	    path:'/userBusiness',
	    name:'UserBusiness',
	    component:() => import('../views/UserBusiness.vue'),
	    meta:{
	      title:'用户管理'
	    }
	  },
	  {
	    path:'/userExtension',
	    name:'UserBusiness',
	    component:() => import('../views/UserExtension.vue'),
	    meta:{
	      title:'用户管理'
	    }
	  },
	  {
	    path:'/userCustomer',
	    name:'UserCustomer',
	    component:() => import('../views/UserCustomer.vue'),
	    meta:{
	      title:'用户管理'
	    }
	  },
      {
        path:'/problem',
        name:'Problem',
        component:() => import('../views/Problem.vue'),
        meta:{
          title:'常见问题'
        }
      },
	  {
	    path:'/problemEdit',
	    name:'ProblemEdit',
	    component:() => import('../views/ProblemEdit.vue'),
	    meta:{
	      title:'常见问题'
	    }
	  },
      {
        path:'/toCash',
        name:'Cash',
        component:() => import('../views/Cash.vue'),
        meta:{
          title:'零钱提现'
        }
      },
      {
        path:'/advert',
        name:'Advert',
        component:() => import('../views/Advert.vue'),
        meta:{
          title:'广告管理'
        }
      },
	  {
	    path:'/cinema',
	    name:'Cinema',
	    component:() => import('../views/Cinema.vue'),
	    meta:{
	      title:'影院规则'
	    }
	  },
	  {
	    path:'/Contracting',
	    name:'Contracting',
	    component:() => import('../views/Contracting.vue'),
	    meta:{
	      title:'影院承包'
	    }
	  },

    {
	    path:'/cardcoucher',
	    name:'CardCoucher',
	    component:() => import('../views/cardcoucher.vue'),
	    meta:{
	      title:'卡券管理'
	    }
	  },
    {
	    path:'/cardcoucherDH',
	    name:'cardcoucherDH',
	    component:() => import('../views/cardcoucherDH.vue'),
	    meta:{
	      title:'卡券管理'
	    }
	  },
    {
	    path:'/cardcoucherGB',
	    name:'cardcoucherGB',
	    component:() => import('../views/cardcoucherGB.vue'),
	    meta:{
	      title:'卡券管理'
	    }
	  },
    {
	    path:'/cardcoucherDJ',
	    name:'cardcoucherDJ',
	    component:() => import('../views/cardcoucherDJ.vue'),
	    meta:{
	      title:'卡券管理'
	    }
	  },
    {
	    path:'/cardcoucherList',
	    name:'cardcoucherList',
	    component:() => import('../views/cardcoucherList.vue'),
	    meta:{
	      title:'卡券列表'
	    }
	  },
    
    {
	    path:'/menuUser',
	    name:'menuUser',
	    component:() => import('../views/MenuUser.vue'),
	    meta:{
	      title:'权限管理'
	    }
	  },
    {
	    path:'/menuAuth',
	    name:'menuAuth',
	    component:() => import('../views/MenuAuth.vue'),
	    meta:{
	      title:'权限管理'
	    }
	  },

    
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// router.beforeEach((to,from,next)=>{
//   // console.log(to.path)
//   if(to.path != '/login'){
//     let uInfo = localStorage.getItem('userInfo') || undefined
//     if(uInfo){
//       // socketCon()
//       next()
//     }else{
//       next('/login')
//     }
//   }
//   next()
// })

export default router
