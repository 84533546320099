import moment from 'moment'
let common = {
    // 计算时差
    overtime(start,end){
		let st,en,diff
		st = moment(start).valueOf();
		en = moment(end).valueOf();
		diff = moment(en).diff(moment(st), 'seconds') 
        
		if(diff >= 900){
            return true
        } else{
            return false
        }
	},
    // 补全统计图数据
    completion:(data,config)=>{
        // 全部应该存在的数据
        let arr1 = []
        // 后端获取的数据
        let arr2 = []
        for (let index = 0; index < 7; index++) {
            arr1.push(moment().subtract(index, "days").format("MM月DD日")) 
        }
        arr1 = arr1.reverse()


        data.forEach(item => {
            if(item.days.includes('年')){
                item.days = item.days.split('年')[1]
            }
        });

        data.forEach(element => {
            arr2.push(element.days)
        });
      

        // console.log("arr1:",arr1)
        // console.log("arr2:",arr2)

        let conObj = {}
        config.forEach(item => {
            conObj[`${item}`] = 0
        })
        // 数据对比，补全数据
        let newData = []
        arr1.forEach((item,index) =>{
            let ind = arr2.indexOf(item)
            // console.log(ind)
            if(ind == -1){
                let obj = {
                    days:item
                }
                Object.assign(obj,conObj)
                newData[index] = obj
            }else{
                data.forEach((element,i) => {
                    if(element.days == item){
                        newData[index] = data[i]
                    }
                })
            }
        })
        // console.log(newData)
        return newData
    },

    
   
}

export default common