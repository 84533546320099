import advert from './advert'
import cardcoucher from './cardcoucher'
import cinema from './cinema'
import first from './first'
import menu from './menu'
import order from './order'
import problem from './problem'
import toCash from './toCash'
import user from './user'

let auth_list = advert
                .concat(cardcoucher)
                .concat(cinema)
                .concat(first)
                .concat(menu)
                .concat(order)
                .concat(problem)
                .concat(toCash)
                .concat(user)
export default auth_list