<template>
    <div id="app">
        <router-view />
    </div>
</template>

<style lang="less">

</style>
<script>
import $ from "jquery";
export default {
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            isRouterAlive: true,
        };
    },
    methods: {
        // reload() {
        //     this.isRouterAlive = false;
        //     this.$nextTick(function () {
        //         this.isRouterAlive = true;
        //     });
        // },
        // beforeunloadHandler(e) {
        //     console.log('关闭窗口之后')
        //     this.$localStorage.removeItem('menus')
        //     this.$localStorage.removeItem('operation_auth')
        //     this.$localStorage.removeItem('userInfo')
        // }
    }, //methods
    mounted(){
        // window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    }
};
</script>
