import store from "../store/index.js";
const localStorage = window.localStorage

/**
 * localStorage 设置
 * @param {String} key 键
 * @param {String} val 值
 */
export function setItem(key, value, expireSeconds,obj = true) {
    if(obj){
        localStorage[key] = JSON.stringify({
            ...value,
            expired:expireSeconds === undefined ? undefined : (Date.now() + 1000 * expireSeconds)
        })
    }else{
        console.log('setItem',value);
        localStorage[key] = value
    }
}

/**
 * localStorage 获取
 * @param {String} key 键
 * @return {Boolean} key 对应 localStorage 的值
 */
export function getItem(key,obj = true) {
    
    if (localStorage[key] === undefined) {
        return
    }
    if(obj){
        var o = JSON.parse(localStorage[key])
        if (o.expired === undefined || Date.now() < o.expired) {
            console.log('------------------',o)
            return o
        } else {
            console.log('------------------过期了、、、、、')
            delete localStorage[key]
            store.commit('removeUInfo')
        }
    }else{
        console.log('getItem',localStorage[key]);
        return localStorage[key]
    }
}

/**
 * 清除 localStorage，若不填参数 key ，则清除所有 localStorage
 * @param {String} key 键
 * @return {Boolean} 是否清除成功
 */
export function removeItem(key) {
    try {
        if (typeof key === 'undefined') return localStorage.clear()
        return localStorage.removeItem(key)
    } catch (err) {
        return false
    }
}

/**
 * 检查是否支持 localStorage
 * @return {Boolean} 当前浏览器是否支持 localStorage
 */
export function support() {
    try {
        localStorage.setItem('key', 'value')
        if (localStorage.getItem('key') === 'value') {
            localStorage.removeItem('key')
            return true
        } else {
            return false
        }
    } catch (err) {
        return false
    }
}

// 默认导出全量方法
export default {
    getItem,
    setItem,
    removeItem,
    support
}