import qs from "qs";
import axios from "axios"
import { Message } from 'element-ui';
import store from "../store/index.js";
import router from '../router'
import localStorage from '../utils/localStorage'
import auth_list from '../auth/index'

// console.log(store.state)
let stopRequest  = false
let operation_auth = localStorage.getItem('operation_auth',true)?localStorage.getItem('operation_auth',true):[]

const serve = axios.create({
    timeout:500000,
    // baseURL:store.state.baseUrl
	
})

serve.interceptors.request.use(
    (config)=>{
        let hasAuth = false,auth_item = {}
        delete operation_auth.expired
        for (const key in operation_auth) {
            if (Object.hasOwnProperty.call(operation_auth, key)) {
                const item = operation_auth[key];
                // console.log('!!!',operation_auth[key],item.path,config.path)

                if(item.path== '/menuAuth'||item.path == '/menuUser'){
                    item.path = '/menuUser,/menuAuth'
                }
                if(item.path== '/user'||item.path == '/userBusiness'||item.path == '/userExtension'||item.path == '/userCustomer'){
                    item.path = '/user,/userBusiness,/userExtension,/userCustomer'
                }
                if(item.path.indexOf('cardcoucher')!=-1){
                    item.path = '/cardcoucher,/cardcoucherDH,/cardcoucherGB,/cardcoucherDJ,/cardcoucherList'
                }

                if(item.path.indexOf(config.path)!=-1){
                    // console.log(item.path,config.path)
                    hasAuth = true
                    auth_item = item
                }
            }
        }
        // console.log('有没有权限啊~~~~~~~',hasAuth);

        if(config.path!=''&&!hasAuth){ //没有编辑权限
            console.log('-------------',config.path,hasAuth)
            console.log('-------------',auth_list,config.url)
            if(auth_list.indexOf(config.url)==-1){//又不在查看接口中
                console.log(111111111111111)
                stopRequest = true
                Message.error("没有权限")
                return
            }
        }
        
        if(config.url != '/sys/login'&&!store.state.userInfo){
            console.log(2222222222222222,!store.state.userInfo)
            Message.error("登录失效，请重新登录")
            router.push({
                path:'/login'
            })
        }
        
        if(store.state.userInfo){
            config.headers.token = store.state.userInfo.token
        }
        // return
        // console.log(config)
        // config.headers['Content-Type'] = 'application/json;utf-8'
        config.data = JSON.stringify(config.data)   
        console.log(3333333333333,config.data)
        return config
    },
    (error) =>{
        console.log(error)
        Promise.reject(error)
    }
)

serve.interceptors.response.use(
    (res) => {
        if(res.data.code == 0){
            return res;
        }
        if(res.data.code == -1){
            Message.error(res.data.msg);
            return res
        }
    },
    (error) => {
        console.log("err" + error);
        if(!stopRequest){
            Message.error("连接超时(后台不能连接，请联系系统管理员)")
        }
        return Promise.reject(error);
    }
)

export default serve