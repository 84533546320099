import Vue from 'vue'
import Vuex from 'vuex'
import localStorage from '../utils/localStorage'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
		baseUrl:'http://api.yjpiao.cn', // 正式域名
  //  baseUrl:'http://dev-api.yjpiao.cn/', // 测试域名
   userInfo: localStorage.getItem('userInfo')|| undefined
  //  JSON.parse(localStorage.getItem('userInfo')) || undefined
  },
  mutations: {
    setUInfo(state, info){
			state.userInfo = info
		},
    removeUInfo(state){
      state.userInfo = null
    }
  },
  actions: {
  },
  modules: {
  }
})

export default store
